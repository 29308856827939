// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// AppBaseLayout

.wrapper {
  position: relative;
  //padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.content {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.footer {
  & {
    font-size: 12px;
    color: var(--muted-color-text);
    opacity: .45;
    transition: opacity .15s ease;

    &:hover {
      opacity: 1;
    }
  }

  &Content {
    display: flex;
    border-top: 1px solid rgba(@border-color, .8);
    align-items: center;
    justify-content: space-between;
    button{
      font-size: 12px;
      font-weight: 400;
    }
  }
}