// Core
@import "../../styles/core/_breakpoints";
@import "../../styles/core/_colors";
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_typography";
@import "../../styles/core/_animations";
// Debug

.wrapper {
  margin-top: 1rem;
  overflow: auto;
  font-size: .8rem;
  line-height: 1.2;
  word-break: break-word;

  code {
    font-size: 10px;
  }
}

.panel {
  white-space: pre-wrap;
  font-size: 0.75rem;
}
