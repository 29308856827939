// Core
@import "../../styles/core/_breakpoints";
@import "../../styles/core/_colors";
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_typography";
@import "../../styles/core/_animations";
// PaymentStatusModal


.wrapper {
    text-align: center;
    margin: 0 auto;
    max-width: 380px;
    .heading {
        .font-semibold-24;
    }
    .text {
        .font-regular-16;
        padding-top: 12px;
        padding-bottom: 24px;
    }
    .supportLink {
        cursor: pointer;
        .font-semibold-14;
        color: var(--danger-color);
        .success& {
            color: var(--success-color);
        }
    }
}