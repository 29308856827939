// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Button

.wrapper {

    :global {
        // todo: TEMPORARY FIX (loading 1s when mount)
        .ant-btn-loading-icon {
            display: none !important;
        }
    }

    .loadingIcon {
        animation: loadingCircle 1s infinite linear;
    }

    // TODO: UPDATE VARS

    &.default {
        &,
        &:hover,
        &:focus {
            box-shadow: 0 2px 5px rgba(222, 226, 237, 0.25);
            background: var(--widget-bg-color);
            border: 1px solid @border-color;
            color: var(--text-main-color);
        }

        // Status
        &:hover,
        &:focus {
            color: var(--brand-color);
            box-shadow: 0 3px 6px rgba(222, 226, 237, 0.25);
        }
    }

    &.link {
        &,
        &:hover,
        &:focus {
            display: inline-block;
            background: transparent;
            border: none;
            color: var(--link-color);
        }

        // Status
        &:hover,
        &:focus {
            filter: brightness(1.1);
        }
    }

    &.text {
        &,
        &:hover,
        &:focus {
            display: inline-block;
            background: transparent;
            border: none;
        }

        // Status
        &:hover,
        &:focus {
            filter: brightness(1.1);
        }
    }

    &.primary {
        .addButtonVariant(var(--brand-color), var(--brand-color-rgba));
    }

    &.cta {
        &,
        &:hover,
        &:focus {
            border: none;
            box-shadow: var(--cta-button-shadow);
            background: var(--cta-button-bg);
            color: var(--cta-button-content-color);
        }

        // Status
        &:hover,
        &:focus {
            filter: brightness(1.05);
            box-shadow: var(--cta-button-shadow-hover);
        }
    }

    &.primaryOutlined {
        .addButtonOutlined(var(--brand-color));
    }

    &.success {
        .addButtonVariant(var(--success-color), var(--success-color-rgba));
    }

    &.successOutlined {
        .addButtonOutlined(var(--success-color), var(--success-color-rgba));
    }

    &.warning {
        .addButtonVariant(var(--warning-color), var(--warning-color-rgba));
    }

    &.warningOutlined {
        .addButtonOutlined(var(--warning-color), var(--warning-color-rgba));
    }

    &.danger {
        .addButtonVariant(var(--danger-color), var(--danger-color-rgba));
    }

    &.dangerOutlined {
        .addButtonOutlined(var(--danger-color), var(--danger-color-rgba));
    }

    &.unable {
        .addButtonVariant(var(--unable-color), var(--unable-color-rgba));
    }
}