// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// ErrorPage

.wrapper {
    padding-top: 150px;
    .sm({
        padding-top: 20px;
    });

    .image {
        & > svg {
            width: 100%;
            max-width: 670px;
        }
    }

    .content {
        text-align: center;
    }

    .heading {
        .font-regular-42;
        .sm({
            .font-semibold-24;
        })
    }

    .text {
        .font-regular-20;
        color: var(--text-alt-color);
        .sm({
            .font-regular-16;
        })
    }
}