/**
    Example of use:
    @media @tablet {
      footer {
        width: 940px;
      }
    }
 */
.font-semibold-42 {
  font-size: 42px;
  line-height: 119%;
  font-weight: 600;
}
.font-semibold-34 {
  font-size: 34px;
  line-height: 124%;
  font-weight: 600;
}
.font-semibold-30 {
  font-size: 30px;
  line-height: 127%;
  font-weight: 600;
}
.font-semibold-24 {
  font-size: 24px;
  line-height: 133%;
  font-weight: 600;
}
.font-semibold-20 {
  font-size: 20px;
  line-height: 140%;
  font-weight: 600;
}
.font-semibold-18 {
  font-size: 18px;
  line-height: 133%;
  font-weight: 600;
}
.font-semibold-16 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}
.font-semibold-14 {
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
}
.font-semibold-12 {
  font-size: 12px;
  line-height: 167%;
  font-weight: 600;
}
.font-medium-30 {
  font-size: 30px;
  line-height: 127%;
  font-weight: 500;
}
.font-medium-16 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}
.font-medium-14 {
  font-size: 14px;
  line-height: 171%;
  font-weight: 500;
}
.font-regular-42 {
  font-size: 42px;
  line-height: 119%;
  font-weight: 400;
}
.font-regular-20 {
  font-size: 20px;
  line-height: 140%;
  font-weight: 400;
}
.font-regular-18 {
  font-size: 18px;
  line-height: 140%;
  font-weight: 400;
}
.font-regular-16 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}
.font-regular-14 {
  font-size: 14px;
  line-height: 157%;
  font-weight: 400;
}
.font-regular-12 {
  font-size: 12px;
  line-height: 167%;
  font-weight: 400;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
/* SFProText-Regular */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../media/fonts/SFProText-Regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* SFProText-Medium */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../media/fonts/SFProText-Medium.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* SFProText-Semibold */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../media/fonts/SFProText-Semibold.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Semibold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* SFProText-Bold */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../media/fonts/SFProText-Bold.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
:root {
  --xs: 576;
  --sm: 768;
  --md: 992;
  --lg: 1200;
  --xl: 1400;
  --xxl: 1920;
  --header-height: 73px;
  --container-width: 1440px;
  --container-padding: 0 135px;
  --container-padding-md: 0 15px;
  --border-radius-base: 8px;
  --button-border-radius: var(--border-radius-base);
  --brand-color: #5c78c1;
  --brand-content-color: #fff;
  --cta-button-bg: var(--brand-color);
  --cta-button-content-color: var(--brand-content-color);
  --cta-button-shadow: 0px 2px 5px rgba(var(--brand-color-rgba), 0.25);
  --cta-button-shadow-hover: 0px 3px 6px rgba(var(--brand-color-rgba), 0.25);
  --heading-font-family: "Nunito", "SFProText", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  --text-main-color: #32343a;
  --text-heading-color: var(--text-main-color);
  --text-alt-color: #6d7588;
  --text-muted-color: #a8adbb;
  --site-bg-color: #f9fafd;
  --header-bg-color: #fff;
  --header-bg-shadow: #fff;
  --widget-bg-color: #fff;
  --widget-bg-shadow: #fff;
  --widget-bg-alt-color: #f8f9ff;
  --widget-bg-alt-shadow: #f8f9ff;
  --disabled-bg-color: #f3f4f7;
  --muted-color: #e7ecf9;
  --link-color: var(--brand-color);
  --success-color: #78cd62;
  --warning-color: #ffc53d;
  --processing-color: #40a9ff;
  --unable-color: #bec7da;
  --danger-color: #d63636;
  --error-color: var(--danger-color);
  --untouched-status-color: var(--warning-color);
  --claimed-status-color: var(--warning-color);
  --draft-status-color: var(--warning-color);
  --not-imposed-status-color: var(--warning-color);
  --pending-status-color: #9254de;
  --in-work-status-color: var(--processing-color);
  --up-for-grabs-status-color: var(--processing-color);
  --completed-status-color: var(--success-color);
  --submitted-status-color: var(--success-color);
  --ready-status-color: var(--success-color);
  --resolved-status-color: var(--success-color);
  --aborted-status-color: var(--danger-color);
  --removed-status-color: var(--danger-color);
  --unable-to-commence-status-color: var(--danger-color);
  --unable-to-proceed-status-color: var(--danger-color);
  --withdrawn-status-color: var(--danger-color);
  --declined-status-color: var(--danger-color);
  --disputed-status-color: var(--danger-color);
  --canceled-status-color: var(--danger-color);
  --refunded-status-color: var(--danger-color);
  --customer-color: #722ed1;
  --writer-color: #5abd4a;
  --support-color: #faad14;
  --badge-bg-color: var(--danger-color);
  --badge-content-color: var(--brand-content-color);
  --extra-feature-type-1-bg: linear-gradient(245.54deg, rgba(171, 217, 255, 0.3) 1.71%, rgba(195, 108, 217, 0.3) 96.3%);
  --extra-feature-type-1-icon: '/img/extra-features/default/type-1.png';
  --extra-feature-type-1-color: #6D7588;
  --extra-feature-type-2-bg: linear-gradient(67.85deg, #FFD089 0%, #7DEA6B 112.03%);
  --extra-feature-type-2-icon: '/img/extra-features/default/type-2.png';
  --extra-feature-type-2-color: #fff;
  --extra-feature-type-3-bg: linear-gradient(245.28deg, #FFCD83 0%, #FF84DC 100%), linear-gradient(245.28deg, #FFEDAB 0%, #D738FF 100%);
  --extra-feature-type-3-icon: '/img/extra-features/default/type-3.png';
  --extra-feature-type-3-color: #fff;
  --extra-feature-type-4-bg: linear-gradient(107.79deg, #FFF383 2.8%, #F48D52 95.04%);
  --extra-feature-type-4-icon: '/img/extra-features/default/type-4.png';
  --extra-feature-type-4-color: #fff;
}
.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.85;
}
.clickable {
  cursor: pointer;
}
.tiny-shadows {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
}
.default-shadows {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.card {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
  padding: 1rem;
  border-radius: var(--border-radius-base);
  background: #fff;
}
.space-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.justify-start {
  justify-content: flex-start;
}
.multiplier {
  font-weight: 600;
  font-style: normal;
  font-size: 0.7rem;
  border-radius: 4px;
  opacity: 0.5;
  padding: 0 1px;
  width: 35px;
  justify-content: center;
  align-self: center;
}
.meta {
  background: rgba(255, 0, 0, 0.1);
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
}
.opacity-5 {
  opacity: 0.5;
}
.ant-card {
  border: 1px solid #edeff6;
  display: flex;
  flex-flow: column;
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  transition: box-shadow 0.2s, border-collor 0.2s;
}
.ant-card .ant-card-body {
  flex: 1;
}
.ant-card .ant-card-head {
  background: #fff;
  border-bottom-color: #edeff6;
}
.ant-card .ant-card-head .ant-card-head-title {
  overflow: visible;
}
.ant-card .ant-card-actions {
  background: var(--widget-bg-alt-color);
  overflow: hidden;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
  display: flex;
}
.ant-card .ant-card-actions li {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn {
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-radius 0s, padding 0s;
}
div.ant-table {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #edeff6;
  overflow: auto;
}
div.ant-table .ant-table-row:last-child td {
  border: none;
}
div.ant-table .ant-table {
  margin: 0 !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
form.default-form .ant-input-number,
form.default-form .ant-input,
form.default-form .ant-select {
  min-width: unset;
  width: 100%;
  max-width: 100%;
}
.ant-form .ant-row {
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .ant-form .ant-form-item {
    flex-flow: wrap;
  }
}
.ant-form-item-label {
  font-size: 14px;
  line-height: 157%;
  font-weight: 400;
  color: var(--text-alt-color);
  padding-bottom: 0 !important;
}
.ant-form-item-label > label {
  height: 28px !important;
}
.ant-form-item-extra {
  color: #7e96ad;
  font-size: 12px;
  padding: 3px 3px 0;
}
.ant-form-item-explain {
  font-size: 12px;
  padding-top: 1px;
}
.ant-tabs {
  overflow: visible;
}
.ant-tag {
  font-weight: 600;
}
.ant-select:not([style*="width"]) {
  min-width: 160px;
}
.ant-select-multiple .ant-select-selection-item-content > div + div {
  display: none;
}
.ant-select-multiple .ant-select-selection-item {
  background: rgba(0, 0, 0, 0.01);
  border-color: rgba(237, 239, 246, 0.2);
  border-radius: 6px;
}
.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content > div {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.ant-select-dropdown {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.ant-transfer-list {
  background: rgba(255, 255, 255, 0.5);
}
.ant-timeline {
  padding-left: 3px;
}
.ant-timeline .ant-timeline-item-label {
  margin-left: 25px;
  position: relative;
  top: -6px;
}
.ant-timeline .ant-timeline-item-head {
  background-color: var(--site-bg-color);
}
.ant-timeline .ant-timeline-item-tail {
  border-left: 1px solid #edeff6;
}
.ant-timeline .ant-timeline-item:last-child .ant-timeline-item-tail {
  display: none;
}
.ant-tooltip-inner {
  max-height: 200px;
  overflow-y: auto;
}
.ant-empty .ant-empty-image {
  height: fit-content;
}
.ant-empty .ant-empty-description {
  color: var(--text-muted-color);
  opacity: 0.75;
  font-size: 0.7rem;
  font-weight: 600;
}
.ant-empty svg {
  height: 60px;
}
.ant-divider-horizontal {
  margin: 4px 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ant-steps .ant-steps-item-title {
  font-family: var(--heading-font-family);
}
.ant-checkbox-inner {
  border-color: var(--brand-color);
}
.ant-radio-inner {
  border-color: var(--brand-color);
}
/**
    Basic styles
 */
html,
body {
  height: 100%;
  margin: 0;
  background: var(--site-bg-color);
  scroll-behavior: smooth;
}
body {
  font-family: "SFProText", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1 {
  margin-bottom: 1rem;
  font-family: var(--heading-font-family);
}
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
  font-family: var(--heading-font-family);
}
ul {
  padding: 0;
  margin: 0;
}
a {
  color: var(--link-color);
}
.ant-tooltip a {
  color: white;
}
a.active {
  color: #1890ff;
}
hr {
  opacity: 0.2;
}
label {
  user-select: none;
}
ul {
  list-style: none;
}
strong {
  font-weight: 600;
}
dd {
  margin: 0;
}
:focus {
  outline: none;
}
/**
    Browser styles
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-main-color) !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
hr {
  border: none;
  border-top: 1px solid #a9b3d4;
}
hr.double {
  padding: 1px 0;
  border-bottom: 1px solid #a9b3d4;
}
small {
  font-size: 0.85em;
}
img {
  max-width: 100%;
}
#root {
  height: 100%;
}
.option-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  justify-content: space-between;
  font-size: 0.8rem;
}
.option-description div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  gap: 7px;
}
.ant-input-textarea-show-count::after {
  font-size: 11px;
  font-weight: 600;
  color: var(--text-muted-color);
}
.grid-cards {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.grid-cards.grid-large {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.grid-cards.grid-normal {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid-cards.grid-small {
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}
.grid-cards.grid-xs {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.flex-grid {
  display: flex;
  gap: 20px;
  grid-gap: 20px;
}
.spin-fast {
  animation: fa-spin 0.3s linear infinite;
}
.ant-upload.ant-upload-drag {
  background: var(--widget-bg-alt-color);
}
@media only screen and (max-width: 768px) {
  .ant-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--vh, 1vh) * 100);
    top: 0;
    width: 100% !important;
    max-width: 100% !important;
    padding-bottom: 0;
    margin: 0;
    transform-origin: 0 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal.ant-zoom-enter,
  .ant-modal.ant-zoom-appear {
    opacity: 1 !important;
    animation: none !important;
    overflow: hidden;
  }
  .ant-modal.ant-zoom-enter .ant-modal-content,
  .ant-modal.ant-zoom-appear .ant-modal-content {
    animation: 0.25s fromDown !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal.ant-zoom-leave.ant-zoom-leave-active {
    overflow: hidden;
    opacity: 0;
    animation: 0.2s fadeSuddenly !important;
  }
  .ant-modal.ant-zoom-leave.ant-zoom-leave-active .ant-modal-content {
    animation: 0.2s toDown !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal-content {
    width: 100%;
    max-width: none;
    border-radius: 8px 8px 0 0;
  }
}
@keyframes fromDown {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes toDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes fadeSuddenly {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (max-width: 768px) {
  .DateTimePickerDropdown .ant-picker-datetime-panel {
    display: block;
  }
  .DateTimePickerDropdown .ant-picker-time-panel .ant-picker-content {
    height: 94px;
  }
  .DateTimePickerDropdown .ant-picker-panel {
    width: 280px;
  }
  .DateTimePickerDropdown .ant-picker-footer {
    max-width: 100%;
  }
  .DateTimePickerDropdown .ant-picker-date-panel .ant-picker-content {
    width: 100%;
  }
  .DateTimePickerDropdown .ant-picker-content {
    display: block;
    padding: 0 12px;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column {
    display: flex;
    width: 100%;
    height: 38px;
    padding: 5px 0;
    overflow: hidden;
    overflow-x: auto;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    padding: 0 10px;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column:not(:first-child) {
    border-left: none;
  }
}
.SearchDateRangeDropdown .ant-picker-panel:last-child {
  width: 0;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header {
  position: absolute;
  right: 0;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
  visibility: hidden;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-body {
  display: none;
}
@media (min-width: 768px) {
  .SearchDateRangeDropdown .ant-picker-panel:last-child {
    width: 280px !important;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header {
    position: relative;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
    visibility: initial;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-body {
    display: block;
  }
}
.SearchDateRange .ant-picker-clear {
  opacity: 0 !important;
}
.SearchDateRange .ant-picker-range {
  width: 100%;
}
