// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Header

//background: @component-background; // todo: then use vars
.wrapper {
    padding: 15px 0;
    background: var(--header-bg-color);
    //border-bottom: @border-color;
    border-bottom: 1px solid @border-color;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// Main navigation
.links {
    font-size: 1.1em;
    font-weight: 500;



    > div:last-child {
        margin-left: 1rem;
    }

    .link {
        color: var(--text-main-color);
        border: 1px solid transparent;
        border-radius: var(--border-radius-base);
        padding: 0 0.4rem;
        transition: none;
        height: 32px;
        display: flex;
        align-items: center;


          .media-breakpoint(360px, {
            padding: 0;

            > span {
              font-size: 14px;
            }

          });






        &Active {
            //background: var(--brand-color);
            box-shadow: 0 4px 8px rgba(var(--brand-content-color-rgba), 1);
            border-color: transparent; // todo: test ui
            color: var(--brand-color);
        }
    }

    .highlight {
        background: var(--brand-color);
        box-shadow: 0 3px 5px rgba(var(--brand-color-rgba), 0.5);
        color: var(--brand-content-color);
        border-radius: 30px;
        padding: 2px;
        font-size: 1.3rem;
        min-width: 15px;
        min-height: 15px;
        margin-right: 1px;
    }
}
