/**
    Basic styles
 */
html,
body {
  height: 100%;
  margin: 0;
  background: var(--site-bg-color);
  scroll-behavior: smooth;
}

body {
  font-family: @font-base;
}

h1 {
  margin-bottom: 1rem;
  font-family: var(--heading-font-family);
}

h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
  font-family: var(--heading-font-family);
}

ul {
  padding: 0;
  margin: 0;
}

a {
  color: var(--link-color);

  .ant-tooltip & {
    color: white;
  }

  &.active {
    color: #1890ff;
  }
}

hr {
  opacity: 0.2;
}

label {
  user-select: none;
}

ul {
  list-style: none;
}

strong {
  font-weight: 600;
}

dd {
  margin: 0;
}

:focus {
  outline: none;
}

/**
    Browser styles
 */

// Search appearance (clear button)
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}

// Change Autocomplete styles in Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-main-color) !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

hr {
  border: none;
  border-top: 1px solid darken(@border-color, 20%);

  &.double {
    padding: 1px 0;
    border-bottom: 1px solid darken(@border-color, 20%);
  }
}

small {
  font-size: 0.85em;
}

img{
  max-width: 100%;
}

#root {
  height: 100%;
}
