// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
.burger {
  padding: 0 !important;
  position: relative;
  svg {
    width: 32px;
    height: 32px;

  }


  &.burgerMark {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: var(--badge-bg-color);
      border: 1px solid var(--badge-content-color)
    }
  }
}

.drawer {
  :global {
    .ant-drawer-content-wrapper {
      .ant-drawer-header-title {
        flex-direction: row-reverse;
        color: var(--text-main-color);

      }

      .ant-drawer-title {
        .font-semibold-24;
      }

      .ant-drawer-close {
        margin-right: 0;
      }

      .ant-drawer-header {
        border-bottom: none;
        padding: 24px 15px 16px;
      }

      .ant-drawer-body {
        padding: 24px 15px;
      }
    }

  }
}

.orderBtn {
  display: block;
  margin-bottom: 12px;

  button {
    width: 100%;
  }
}

.menu {
  display: grid;
  grid-gap: 12px;
}

.menuLink {
  display: flex;
  align-items: center;
  padding: 9px 0;
  gap: 10px;
  color: var(--text-main-color);
  .font-semibold-16;

  svg {
    width: 20px;
    height: 20px;
  }

  &Active {
    color: var(--brand-color);
  }
}

.menuLinkCounter {
  display: block;
  border-radius: 24px;
  padding: 0 5px;
  height: 24px;
  min-width: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  background-color: #CF4141;
}