// Core
@import "../../styles/core/_breakpoints";
@import "../../styles/core/_colors";
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_typography";
@import "../../styles/core/_animations";
// BadgeCounter
.counterVariant(@text-color: white, @bg-color: #e83f3f, @shadow-color:@bg-color) {
    :global {
        .ant-badge-count {
            font-weight: 600;
            height: 17px;
            min-width: 17px;
            line-height: 18px;
            font-size: 11px;
            padding: 0 4px;

            &:not([class*="ant-badge-status"]) {
                color: @text-color;
                background: @bg-color;
                box-shadow: 0 5px 4px -2px rgba(@shadow-color, 0.5), 0 1px 1px -1px @shadow-color;
            }
        }
    }
}

.wrapper {
    z-index: 1;
    font-size: 1rem;

    &.default {
        .counterVariant(#5c6c78, #fff, #b7ccdc);
    }

    &.alert {
        .counterVariant();
    }

    &.tab {
        .counterVariant(#fff, #CF4141, none);
        margin-left: 5px;
        :global {
            .ant-badge-count {
                height: 18px;
                min-width: 18px;
                line-height: 18px;
            }

            .ant-scroll-number-only-unit {
                line-height: 18px;
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
}

.prefix:not(:empty) {
    padding-right: 0.3rem;
    //font-size: 1rem;
}

// Fix font-size
:global {
    span.ant-badge {
        font-size: unset;
    }
}
